@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500|Titillium+Web:600);
body {
  font-family: "Roboto", sans-serif;
  height: 100%;
  background-color: #00599f;
}

nav .container {
  border-bottom: gainsboro 1px solid;
}
nav .container img {
  height: 45px;
}

header nav {
  background: rgba(0, 89, 159, 0.64);
}

html.no-webp main#home .masthead {
  background-image: url("/images/background-2-croped.jpg");
}

html.webp main#home .masthead {
  background-image: url("/images/background-2-croped.webp");
}

main h3 {
  color: #00599f;
  display: inline-block;
  border-bottom: 3px solid #f4b228;
}
main#home {
  margin-top: -72px;
}
main#home .masthead {
  height: 60vh;
  min-height: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
main#home #banner {
  background-color: #00599f;
}
main#home #sponsors .row > figure {
  text-align: center;
  height: 135px;
  margin: 0 0 0px 0;
}
main#home #sponsors .row > figure figcaption {
  -webkit-user-select: none;
          user-select: none;
}
main#home #sponsors .row > figure img {
  transform: scale(1);
  transition: 0.2s ease-in-out;
}
main#home #sponsors .row > figure:hover img {
  transform: scale(1.1);
}
main#home #sponsors img {
  max-height: 110px;
}
main#home #news .card {
  min-height: 230px;
}
main#achievements ul.timeline {
  list-style-type: none;
  position: relative;
}
main#achievements ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
main#achievements ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
main#achievements ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
main#gallery .card img {
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}
main#gallery .card img:hover {
  opacity: 0.7;
}

footer .container-fluid {
  background-color: #00599f;
  color: white;
  padding: 20px 20px 30px 20px;
}
footer .container-fluid h4,
footer .container-fluid h5,
footer .container-fluid h6 {
  font-size: 1.1rem;
  font-family: "Titillium Web", sans-serif;
  margin: 15px 0 8px 0;
}
footer .container-fluid a {
  text-decoration: underline;
  color: white;
}
footer .container-fluid p {
  font-size: 0.85rem;
  margin: 0;
}
footer .container-fluid img {
  height: 40px;
  margin: 0 0 5px 0;
}
footer .mini {
  background-color: #f4b228;
}
footer .mini a {
  color: black;
}

#news .card {
  min-height: 230px;
}

/****************\
|                |
| ADMINISTRATION |
|                |
\****************/
#admin .rdw-editor-toolbar {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  height: initial;
  padding: 0.375rem;
}
#admin .rdw-editor-main {
  padding: 0 0.75rem;
}

#v-pills-galery .card img {
  height: 150px;
}
#v-pills-galery .card .btn-group {
  position: absolute;
  right: 0;
}

.gallery-editor .images-list .card .form-check-input.gallery-select {
  position: absolute;
  right: 6px;
}
.gallery-editor .images-list .card .form-check-input.gallery-cover {
  position: absolute;
  left: 28px;
}
.gallery-editor .images-list .card img {
  height: 120px;
  cursor: pointer;
}
