// Imports
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500|Titillium+Web:600');
// Variables
$blue-color: #00599f;
$orange-color: #f4b228;
// Styles
body {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  background-color: $blue-color;
}

nav .container {
  border-bottom: gainsboro 1px solid;
  img {
    height: 45px;
  }
}

header nav {
  background: rgba(0, 89, 159, 0.64);
}

html.no-webp main#home .masthead {
  background-image: url('/images/background-2-croped.jpg');
}
html.webp main#home .masthead {
  background-image: url('/images/background-2-croped.webp');
}

main {
  h3 {
    color: $blue-color;
    display: inline-block;
    border-bottom: 3px solid $orange-color;
  }
  &#home {
    margin-top: -72px;
    .masthead {
      height: 60vh;
      min-height: 500px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    #banner {
      background-color: $blue-color;
    }
    #sponsors {
      .row > figure {
        text-align: center;
        height: 135px;
        margin: 0 0 0px 0;
        figcaption {
          user-select: none;
        }
        img {
          -webkit-transform: scale(1);
          transform: scale(1);
          -webkit-transition: 0.2s ease-in-out;
          transition: 0.2s ease-in-out;
        }
        &:hover img {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
      img {
        max-height: 110px;
      }
    }
    #news .card {
      min-height: 230px;
    }
  }
  &#achievements {
    ul.timeline {
      list-style-type: none;
      position: relative;
    }
    ul.timeline:before {
      content: ' ';
      background: #d4d9df;
      display: inline-block;
      position: absolute;
      left: 29px;
      width: 2px;
      height: 100%;
      z-index: 400;
    }
    ul.timeline > li {
      margin: 20px 0;
      padding-left: 20px;
    }
    ul.timeline > li:before {
      content: ' ';
      background: white;
      display: inline-block;
      position: absolute;
      border-radius: 50%;
      border: 3px solid #22c0e8;
      left: 20px;
      width: 20px;
      height: 20px;
      z-index: 400;
    }
  }
  &#gallery {
    .card img {
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.3s ease-out;
    }
    .card img:hover {
      opacity: 0.7;
    }
  }
}

footer {
  .container-fluid {
    background-color: $blue-color;
    color: white;
    padding: 20px 20px 30px 20px;
    h4,
    h5,
    h6 {
      font-size: 1.1rem;
      font-family: 'Titillium Web', sans-serif;
      margin: 15px 0 8px 0;
    }
    a {
      text-decoration: underline;
      color: white;
    }
    p {
      font-size: 0.85rem;
      margin: 0;
    }
    img {
      height: 40px;
      margin: 0 0 5px 0;
    }
  }
  .mini {
    background-color: $orange-color;
    a {
      color: black;
    }
  }
}

#news .card {
  min-height: 230px;
}

/****************\
|                |
| ADMINISTRATION |
|                |
\****************/

#admin {
  .rdw-editor-toolbar {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: initial;
    padding: 0.375rem;
  }
  .rdw-editor-main {
    padding: 0 0.75rem;
  }
}
#v-pills-galery .card {
  img {
    height: 150px;
  }
  .btn-group {
    position: absolute;
    right: 0;
  }
}
.gallery-editor {
  .images-list .card {
    .form-check-input {
      &.gallery-select {
        position: absolute;
        right: 6px;
      }
      &.gallery-cover {
        position: absolute;
        left: 28px;
      }
    }
    img {
      height: 120px;
      cursor: pointer;
    }
  }
}
